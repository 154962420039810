<template>
  <!-- 图片上传 -->
  <div class="select-box-img" :class="isFlex?'selectFlex':''">
    <div class="select-title">
      {{$t('my.sczp')}}：
    </div>

    <div class="home">
      <div class="">
        <el-upload :class="{
            showUpload:fileList.length>=limit?true:false,
            borderRadius:uploadBorderRadius == '50%',
          }" action="api/common/file" :multiple="false" :accept="accept ? accept : '.jpg,.png,.jpeg,.gif'"
          :list-type="listType?listType:'picture-card'" :limit='1' :on-remove="handleRemove" :on-success="handleSuccess"
          :file-list="fileList" :disabled='disabled' :auto-upload="true">
          <i   slot="default" class="el-icon-plus"></i>
        </el-upload>
      </div>

      <div v-show="showMsg" class="errorMsg">{{msg}}</div>
    </div>
  </div>
</template>

<script>
  import {
    Loading
  } from 'element-ui';

  import { pathToBase64 } from '../../utils/mmmm-image-tools'
  export default {
    name: 'from-upload',
    props: ['name', 'isRedDot', 'isFlex', 'value', 'isCheck', 'createOss',
      'limit', 'witdh', 'dataType', 'listType', 'accept', 'disabled', 'uploadBorderRadius'
    ],
    data() {
      return {

        msg: '',
        showMsg: false,
        showUpButton: false, //true隐藏 false显示上传按钮
        fileList: [],
        fileUrl: '',
        fileReader: '',
      }
    },

    mounted() {
      console.log(this.value);
      if (this.value) {
        if (this.limit === 1) {
          this.fileList.push({
            url: this.value
          })
        } else {
          this.fileList = this.value
        }
      }
      this.setName(this.name)
      if (!window.FileReader) {
        console.error('Your browser does not support FileReader API!')
      }
      this.fileReader = new FileReader()
      console.log( this.fileReader)
    },
    watch: {
      fileList(val) {
        this.verification()
      },
      value(val) {
        if (val) {
          if (typeof val === 'string') {
            let list = val.split(';');
            list.forEach((url) => {
              this.setFileUrl(url);
            })
          } else {
            val.forEach((url) => {
              if (typeof url === 'string') {
                this.setFileUrl(url);
              } else {
                this.setFileUrl(url.url);
              }
            })
          }
        }
      },
    },
    methods: {
      setFileUrl(url) {
        let isAdd = true;
        this.fileList.forEach((v, k) => {
          if (v.url === url) {
            isAdd = false;
          }
        })
        if (isAdd) {
          this.fileList.push({
            name: this.getFileName(url),
            url: url
          });
        }
        console.log(this.fileList);
      },
      getFileName(o) {
        let pos = o.lastIndexOf("\\");
        return o.substring(pos + 1);
      },


      // 文件列表移除文件时的钩子
      handleRemove(file, fileList) {
        this.fileUrl.forEach((v, i) => {
          if (v == file.url) {
            this.fileUrl.splice(i, 1)
          }
        })
        this.fileList = fileList
      },
      handleSuccess(options) {
        console.log(options)
        if(options.code===1){
          this.fileUrl= options.data.url
          this.$emit('input', options.data.url)
          this.popFun('Tips', 'Upload successfully', 'success')
        }else {
          this.popFun('Tips', 'Upload failure', 'error')
        }




      },
      popFun(title, msg, type) {
        this.$notify({
          title: title,
          message: msg,
          type: type
        });
      },
      // 处理name结尾的冒号
      setName(name) {
        if (this.name) {
          if (name.indexOf('：') != -1) {
            this.title = name.substr(0, name.length - 1)
          } else {
            this.title = name
          }
        }
      },
      verification() {
        if (this.isCheck) {
          this.showMsg = true

          if (this.fileList.length < 1) {
            this.msg = "Upload img";
            return false
          }
          this.showMsg = false
          return true
        } else {
          return true
        }
      },
      alertMsg() {
        if (this.isCheck) {
          if (this.verification) {
            this.$notify.error({
              title: 'Tips',
              message: this.msg
            });
          }
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .selectFlex {
    display: flex;
  }

  .borderRadius {
    ::v-deep .el-upload-list--picture-card .el-upload-list__item-thumbnail {
      width: 84px;
      height: 86px;
      border-radius: 50%;
    }

    ::v-deep .el-upload-list--picture-card .el-upload-list__item {
      width: 84px;
      height: 86px;
      border-radius: 50%;
    }

    ::v-deep .el-upload--picture-card {
      width: 84px;
      height: 86px;
      border-radius: 50%;
      line-height: 100px;
      margin: 0 0 20px 0;
    }
  }

  .select-box-img {
    .home {
      max-width: 610px;
      padding-top: 6px;
      position: relative;
      word-wrap: break-word;

      .showUpload {
        ::v-deep .el-upload--picture-card {
          display: none;
          /* 上传按钮隐藏 */
        }
      }

      .errorMsg {
        min-width: 250px;
        color: #E30C0C;
        font-size: 14px;
        position: absolute;
        padding-top: 2px;
      }
    }

    .select-title {
      font-size: 16px;
      margin-bottom: 3px;
      line-height: 32px;
      color: #fff;

    }

    .isRedDot {
      display: none;
    }
  }
</style>
