<template>
  <div class="content">
    <heads></heads>
    <div style="padding:90px 120px;box-sizing: border-box;">
      <div class="main">
        <div class="main-head" @click="jumpBack">
          <img class="left-right" src="../../assets/market/l.png" alt="">
          <div class="primary">{{ $t('my.cjrz') }}</div>
        </div>
        <div class="line"></div>
        <div class="man-box">
          <div class="man-box-son" v-if="false">
            <el-select v-model="value" @change="changeLang($event)" :placeholder="$t('my.qxz')">
              <el-option v-for="item in list" :key="item.id" :label="item.title" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="man-box-son">
            <from-upload v-model="imgUrl" :data-type="1"></from-upload>
          </div>
          <div class="man-box-son" style="display:flex;algin-items:center;">
            <input type="text" v-model="surname" style="color: #FFF;font-size: 16px;width: 100%;margin-right:10px"
              :placeholder="$t('my.xs')">
            <input type="text" v-model="name" style="color: #FFF;font-size: 16px;width: 100%;"
              :placeholder="$t('my.mz')">
          </div>
          <div class="man-box-son">
            <el-select v-model="value2" @change="changeLang2($event)" :placeholder="$t('my.qxz')">
              <el-option v-for="item in list2" :key="item.id" :label="item.title" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="man-box-son">
            <input type="text" v-model="id" style="color: #FFF;font-size: 16px;width: 100%;"
              :placeholder="$t('my.zjhm')">
          </div>
          <div style="display:flex;">
            <div class="submit" @click="submit">{{ $t('my.tjsh') }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <footerb></footerb> -->
  </div>
</template>

<script>
import heads from '../../components/header.vue'
import footerb from '../../components/footer.vue'
import {getAuthOption, setMemberAuth} from "@/api/member";
import FromUpload from '../../components/input_from/img_upload'
export default {
  data() {
    return {
      getData: [],
      value: '',
      value2: '',
      list: [],
      list2: [],
      nationalityId: '',//国籍id
      paperId: '',//证件id
      id: '',//证件号码
      surname: '',//姓
      name: '',//名
      imgUrl: '',//名
    }
  },
  components: {
    FromUpload,
    footerb,
    heads
  },
  created() {
    this.getList()
  },
  methods: {

    jumpBack() {
      this.$router.push({
        path: '/my',
        query: {
          activeIndex: 2
        }
      })
    },
    getList() {
      getAuthOption().then(res => {
        if (res.code === 1) {
          this.list = res.data.countryList
          this.list2 = res.data.typeList
        }
      })
      // this.$request({
      //   url: '/member/auth-option',
      //   method: 'GET',
      //   params: {
      //     email: this.username,
      //     pass: this.password
      //   }
      // }).then(res => {
      //   if (res.code == 1) {
      //     this.list = res.data.countryList
      //     this.list2 = res.data.typeList
      //   }
      // })
    },
    changeLang(e) {
      this.nationalityId = e
    },
    changeLang2(e) {
      this.paperId = e
    },
    submit() { //提交审核
      if (!this.imgUrl) {
        return
      }
      if (!this.paperId) {
        return
      }
      if (!this.surname) {
        return
      }
      if (!this.name) {
        return
      }
      if (!this.id) {
        return
      }
      setMemberAuth({
        type: 1,
        stateId: this.nationalityId,
        papersType: this.paperId,
        family: this.surname,
        name: this.name,
        id_img:this.imgUrl,
        idNumber: this.id
      }).then(res => {
        if (res.code === 1) {
          this.$message.success(res.msg)
          setTimeout(() => {
            this.jumpBack()
          }, 800)
        }
      })
      // this.$request({
      //   url: '/member/auth',
      //   method: 'GET',
      //   params: {
      //     type: 1,
      //     stateId: this.nationalityId,
      //     papersType: this.paperId,
      //     family: this.surname,
      //     name: this.name,
      //     idNumber: this.id
      //   }
      // }).then(res => {
      //   if (res.code == 1) {
      //     this.$message.success(res.msg)
      //     setTimeout(() => {
      //       this.jumpBack()
      //     }, 800)
      //   }
      // })
    }
  }
};

</script>
<style scoped>

html {
  background: #171e28;
  box-sizing: border-box;
}

.main {
  width: 100%;
  box-sizing: border-box;
  height: auto;
  background: #1F282F;
}

.main-head {
  display: flex;
  align-items: center;
  padding: 22px 0 20px 40px;
  cursor: pointer;
}

.primary {
  font-size: 16px;
  color: #FFF;
}

.left-right {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #171E28;
  margin-bottom: 36px;
}

.man-box {
  width: 100%;
  padding-bottom: 44px;
}

.man-box-son {
  width: 348px;
  margin: 0 auto;
  color: #FFFFFF;
  border-bottom: 1px solid #171E28;
  padding-bottom: 14px;
  margin-bottom: 20px;
}

/deep/ .el-input__inner {
  background: none !important;
  border: none !important;
  color: #FFFFFF;
  width: 100%;
  padding: 0;
  line-height: 0;
}

/deep/ .el-select {
  width: 100%;
}


input::-webkit-input-placeholder {
  color: #c0c4ae !important;
}

input:-moz-placeholder {
  color: #c0c4ae !important;
}

input::-moz-placeholder {
  color: #c0c4ae !important;
}

input:-ms-input-placeholder {
  color: #c0c4ae !important;
}

.submit {
  width: auto;
  height: 48px;
  border-radius: 2px;
  background: #FFC300;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #212121;
  margin-top: 20px !important;
  padding: 0 20px;
  margin: 0 auto;
  cursor: pointer;
}
</style>
