import request from '@/utils/request'

const url = 'api/member/'

//获取阿里云权限 get
export const register = (data) => request(url+'register','get',data,false)
export const login = (data) => request(url+'login','get',data,false)
export const retrievePass = (data) => request(url+'retrieve','get',data,false)
export const getIconList = (data) => request(url+'coin-list','get',data,false)
export const setPayPass = (data) => request(url+'pay-pass','get',data,false)
export const setPassword = (data) => request(url+'set-pass','get',data,false)
export const getMoneyInfo = (data) => request(url+'money','get',data,false)
export const getMemberInfo = (data) => request(url+'info','get',data,false)
export const getAuthOption = (data) => request(url+'auth-option','get',data,false)
export const setMemberAuth = (data) => request(url+'auth','get',data,false)
export const updateMemberInfo = (data) => request(url+'update','get',data,false)
export const getTeamInfo = (data) => request(url+'team_info','get',data,false)
export const getTeamList = (data) => request(url+'team_list','get',data,false)
export const getLoginList = (data) => request(url+'login_list','get',data,false)

